<template>
  <div
    :class="$style.trajectory"
    :style="{
      width: $store.state.home.bigScreen ? '100vw' : '100%',
      height: $store.state.home.bigScreen ? '100vh' : '482px',
      position: $store.state.home.bigScreen ? 'fixed' : '',
      top: '-20px',
      left: 0,
      zIndex: 9999,
    }"
  >
    <div
      :class="$style.select"
      :style="{
        position: $store.state.home.bigScreen ? 'fixed' : '',
        top: '10px',
        height: 'fixcontent',
        right: '120px',
        zIndex: 9999,
      }"
    >
      <a-form-model ref="ruleForm" layout="inline">
        <a-form-model-item>
          <a-range-picker
            v-model="searchForm.timeRange"
            style="width: 320px"
            :show-time="{ format: 'YYYY-MM-DD HH:mm' }"
            :ranges="presetTime"
            format="YYYY-MM-DD HH:mm"
            :placeholder="[
              $t('hat.clockRecord.startDate'),
              $t('hat.clockRecord.endDate'),
            ]"
            @change="handleSearchTime"
            @ok="handleChange"
            :disabled-date="disabledDate"
            :disabled="play"
            :style="{
              backgroundColor: bigScreenSelectColor,
            }"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 105px"
            v-model="searchForm.userId"
            :placeholder="$t('hat.managementModal.event.member')"
            allow-clear
            @change="handleChange"
            :disabled="play"
            :style="{
              backgroundColor: bigScreenSelectColor,
            }"
          >
            <a-select-option
              v-for="item in users"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 105px"
            v-model="searchForm.fence"
            :placeholder="$t('hat.clockRecord.electronicFence')"
            allow-clear
            @change="handleSelectFence"
            :disabled="play"
            :style="{
              backgroundColor: bigScreenSelectColor,
            }"
          >
            <a-select-option
              v-for="item in fenceList"
              :key="item.geofenceId"
              :value="item.geofenceId"
            >
              {{ item.geofenceName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <div style="position: relative">
            <a-select
              style="width: 75px"
              v-model="searchForm.speed"
              placeholder="1.0X"
              allow-clear
              @change="handleSelectSpeed"
              :disabled="play"
              :style="{
                backgroundColor: bigScreenSelectColor,
              }"
            >
              <a-select-option
                v-for="(item, index) in speed"
                :key="index"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
            <a-button
              type="primary"
              size="small"
              style="margin-left: 10px; min-width: 50px; height: 30px"
              @click="handlePlay"
            >
              {{
                !play
                  ? $t('hat.managementModal.trajectory.play')
                  : $t('generalView.key.stop')
              }}
            </a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div :class="$style.map">
      <TrajectoryMap
        :color="record.color"
        ref="trace"
        @stop-play="handleStopPlay"
        @map-loaded="handleMapLoaded"
      />
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import TrajectoryMap from './trajectory-map.vue';
import { getTrace } from '@/services/trace/index';
import { getFenceListById } from '@/services/smart-hat/geofence';
import { getTraceParamList } from '@/services/smart-hat/device';
import moment from 'moment';
@Component({
  components: {
    TrajectoryMap,
  },
})
export default class Trajectory extends Vue {
  @Prop({ type: Object, default: () => {} }) record;
  bigScreenSelectColor = '#fff';
  project = [];
  users = [];
  fenceList = [];
  offsetDays = 31 * 24 * 60 * 60 * 1000;
  created() {
    this.$store.commit('home/setBigScreen', false);
  }
  // 预设时间
  get presetTime() {
    return {
      [this.$t('generalView.filter.day')]: [
        moment().startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('screen.last3')]: [
        moment()
          .add(-3, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last7')]: [
        moment()
          .add(-7, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last30')]: [
        moment()
          .add(-30, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
    };
  }
  disabledDate(current) {
    if (this.timeString[0]) {
      let selectV = moment(this.timeString[0], 'YYYY-MM-DD').valueOf();
      //禁用选定时间前后6天和今天之后的日期
      return (
        current >
          moment(
            this.formatDate(
              new Date(selectV + this.offsetDays).getTime(),
              'Y-m-d',
            ),
          ) ||
        current <
          moment(
            this.formatDate(
              new Date(selectV - this.offsetDays).getTime(),
              'Y-m-d',
            ),
          ) ||
        current > moment().endOf('date')
      );
    } else {
      // return current > moment().endOf('day'); //禁用今天之后的日期
    }
  }
  formatDate(timestamp, formatLayout = 'Y-m-d H:i:s') {
    let formatDate = '';
    formatLayout = formatLayout.toUpperCase();
    timestamp = (timestamp + '').length > 11 ? timestamp : timestamp * 1000;
    let time = new Date(timestamp);
    for (let i in formatLayout) {
      if (['Y', 'M', 'D', 'W', 'H', 'I', 'S'].indexOf(formatLayout[i]) >= 0) {
        switch (formatLayout[i]) {
          case 'Y':
            formatDate += time.getFullYear();
            break;
          case 'M':
            formatDate +=
              time.getMonth() >= 9
                ? time.getMonth() + 1
                : '0' + (time.getMonth() + 1);
            break;
          case 'D':
            formatDate +=
              time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
            break;
          case 'W':
            formatDate += time.getDay() == 0 ? 7 : time.getDay();
            break;
          case 'H':
            formatDate +=
              time.getHours() > 9 ? time.getHours() : '0' + time.getHours();
            break;
          case 'I':
            formatDate +=
              time.getMinutes() > 9
                ? time.getMinutes()
                : '0' + time.getMinutes();
            break;
          case 'S':
            formatDate +=
              time.getSeconds() > 9
                ? time.getSeconds()
                : '0' + time.getSeconds();
            break;
        }
      } else {
        formatDate += formatLayout[i];
      }
    }
    return formatDate;
  }

  searchForm = {
    timeRange: [],
    speed: undefined,
    userId: undefined,
    fence: undefined,
  };
  timeString = [];

  play = false;
  speed = [
    '0.1X',
    '0.5X',
    '0.8X',
    '1.0X',
    '1.2X',
    '1.5X',
    '2.0X',
    '5.0X',
    '10X',
    '20X',
  ];
  startValue = null;
  endValue = null;

  mounted() {
    //轨迹线接口
    this.setDateTime();
    this.getFenceList();
    this.getProjectsUsers();
    this.addTraceLine();
  }
  setDateTime() {
    var dateNow = Date.now();
    var seven = 7 * 24 * 60 * 60 * 1000;
    var datePre = dateNow - seven;
    this.timeString = [
      this.$moment(datePre)
        .startOf('date')
        .format('YYYY-MM-DD HH:mm:ss'),
      this.$moment(dateNow)
        .endOf('date')
        .format('YYYY-MM-DD HH:mm:ss'),
    ];
    this.searchForm.timeRange = this.timeString;
  }
  async getProjectsUsers() {
    const { deviceId } = this.record;
    const result = await getTraceParamList(deviceId);
    this.project = result.project;
    this.users = result.user;
  }
  handleChange() {
    this.dataLoaded = false;
    this.addTraceLine();
  }
  mapLoaded = false;
  handleMapLoaded() {
    this.mapLoaded = true;
  }
  dataLoaded = false;
  mapData = {};
  async addTraceLine() {
    const hasDate = this.timeString.every(item => item);
    const { userId } = this.searchForm;
    let body = {
      deviceId: this.record.deviceId,
      userId,
    };
    if (hasDate && this.timeString.length) {
      const [d1, d2] = [
        this.$moment(this.timeString[0]).format('YYYY-MM-DD HH:mm:ss'),
        this.$moment(this.timeString[1]).format('YYYY-MM-DD HH:mm:ss'),
      ];
      var startTime = new Date(d1).getTime();
      var endTime = new Date(d2).getTime();
      body.startTime = startTime;
      body.endTime = endTime;
    }
    // TODO 恢复
    let res = await getTrace(body);
    // console.log(getTrace);
    // let res = [
    //   {
    //     lng: '113.45694',
    //     lat: '23.167198',
    //     userId: '',
    //     time: 1729125663842,
    //     userAvatar:
    //       'https://static-legacy.dingtalk.com/media/lADPDiQ3R49dO0fNAqzNAqw_684_684.jpg',
    //     type: 'POSITION',
    //     speed: 0,
    //     altitude: 0,
    //     cumulativeDistance: -1,
    //     content: '',
    //   },
    //   {
    //     lng: '113.45694',
    //     lat: '23.167098',
    //     userId: '',
    //     time: 1729125663842,
    //     userAvatar:
    //       'https://static-legacy.dingtalk.com/media/lADPDiQ3R49dO0fNAqzNAqw_684_684.jpg',
    //     type: 'EVENT',
    //     speed: 0,
    //     altitude: 0,
    //     cumulativeDistance: -1,
    //     content: '近电预警',
    //   },
    //   {
    //     lng: '113.45494',
    //     lat: '23.167398',
    //     userId: '',
    //     time: 1729125663842,
    //     userAvatar:
    //       'https://static-legacy.dingtalk.com/media/lADPDiQ3R49dO0fNAqzNAqw_684_684.jpg',
    //     type: 'IN_GEOFENCE',
    //     speed: 0,
    //     altitude: 0,
    //     cumulativeDistance: -1,
    //     content: '1733311806074777602',
    //   },
    //   {
    //     lng: '113.45394',
    //     lat: '23.167088',
    //     userId: '',
    //     time: 1729125663842,
    //     userAvatar:
    //       'https://static-legacy.dingtalk.com/media/lADPDiQ3R49dO0fNAqzNAqw_684_684.jpg',
    //     type: 'OUT_GEOFENCE',
    //     speed: 0,
    //     altitude: 0,
    //     cumulativeDistance: -1,
    //     content: '1733311806074777602',
    //   },
    //   {
    //     lng: '113.45894',
    //     lat: '23.167188',
    //     userId: '',
    //     time: 1729125663842,
    //     userAvatar:
    //       'https://static-legacy.dingtalk.com/media/lADPDiQ3R49dO0fNAqzNAqw_684_684.jpg',
    //     type: 'PHOTO',
    //     speed: 0,
    //     altitude: 0,
    //     cumulativeDistance: -1,
    //     content: 'smart_helmet/868177060157374/2024-10-11.09.49.05.jpg',
    //   },
    //   {
    //     lng: '113.45794',
    //     lat: '23.166988',
    //     userId: '',
    //     time: 1729125663842,
    //     userAvatar:
    //       'https://static-legacy.dingtalk.com/media/lADPDiQ3R49dO0fNAqzNAqw_684_684.jpg',
    //     type: 'VIDEO',
    //     speed: 0,
    //     altitude: 0,
    //     cumulativeDistance: -1,
    //     content: 'smart_helmet/868177060162150/2024-09-02.16.52.08.mp4',
    //   },
    //   {
    //     lng: '113.45794',
    //     lat: '23.167188',
    //     userId: '',
    //     time: 1729125663842,
    //     userAvatar:
    //       'https://static-legacy.dingtalk.com/media/lADPDiQ3R49dO0fNAqzNAqw_684_684.jpg',
    //     type: 'AUDIO',
    //     speed: 0,
    //     altitude: 0,
    //     cumulativeDistance: -1,
    //     content: 'smart_helmet/868177060157374/2024-10-08.10.15.07.amr',
    //   },
    // ];
    if (res.length === 0) {
      this.$refs.trace.createTracePath2([]);
      return;
    }
    const pathList = res.map(item => {
      return [+item.lng, +item.lat];
    });
    // .reverse();
    const timeList = res.map(item => {
      return this.$moment(item.time).format('YYYY-MM-DD HH:mm:ss');
    });
    // .reverse();
    const avatarList = res.map(item => {
      return item.userAvatar;
    });
    // .reverse();
    this.mapData = {
      path: pathList,
      time: timeList,
      avatar: avatarList,
      result: res,
    };
    this.dataLoaded = true;
  }
  get loaded() {
    if (this.mapLoaded && this.dataLoaded) {
      return true;
    }
    return false;
  }
  @Watch('loaded', { immediate: true })
  onLoaded(val) {
    if (val) {
      this.$refs.trace.createTracePath2(this.mapData);
    }
  }
  handleSearchTime(time, timeString) {
    this.timeString = timeString;
  }

  handleSelectFence(id) {
    this.$refs.trace.deleteCircle();
    id && this.$refs.trace.handleFenceChange(id, this.fenceList);
  }
  handleSelectSpeed() {
    if (!this.searchForm.speed) return;
    this.$refs.trace.setSpeed(this.searchForm.speed);
  }
  handleStopPlay() {
    this.play = false;
  }
  handlePlay() {
    if (!this.play) {
      this.$refs.trace.play();
      this.play = true;
    } else {
      this.$refs.trace.stop();
      this.play = false;
    }
  }
  async getFenceList() {
    this.fenceList = await getFenceListById({
      deviceGroupIds: this.record.deviceId,
    });
  }
  errorCaptured() {}
}
</script>

<style lang="less" module>
.trajectory {
  .map {
    margin-top: 15px;
  }
}
</style>
