<template>
  <div :class="$style.mapWrap">
    <div :class="$style.screen" style="z-index: 9999999">
      <x-icon
        :type="
          $store.state.home.bigScreen
            ? 'tc-icon-fullscreen-exit'
            : 'tc-icon-fullscreen'
        "
        :class="$style.icon"
        @click="handleScreen"
      />
    </div>
    <div
      id="boxMap"
      :class="$style.boxMap"
      :style="{
        width: $store.state.home.bigScreen ? '100vw' : '100%',
        height: $store.state.home.bigScreen ? '100vh' : '430px',
      }"
    ></div>
    <div :class="$style.time">
      <span :class="$style.ff"
        >{{ $t('hat.managementModal.event.time') }}:{{ time }}</span
      >
      <span :class="$style.ll">
        {{ $t('hat.managementModal.personInfo.longitude') }}:{{
          location.split(',')[0]
        }}
      </span>
      <span :class="$style.dd"
        >{{ $t('hat.managementModal.personInfo.latitude') }}:{{
          location.split(',')[1]
        }}
      </span>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  funAMap,
  createPathSimplifier,
  INIT_SPEED,
} from '../../electron-fence/components/util';
import bluehat from '@/assets/images/manage-model/365.png';
import yellowhat from '@/assets/images/manage-model/366.png';
import redhat from '@/assets/images/manage-model/367.png';
import whitehat from '@/assets/images/manage-model/368.png';
import orangehat from '@/assets/images/manage-model/369.png';
import { getZonesData } from '@/services/smart-hat/geofence';
import {
  drawCharts,
  formatToApi,
  mouseEvent,
} from '@/views/hat/electron-fence/components/util';

import { UploadService } from '@triascloud/services';
import { uuid } from '@triascloud/utils';
import Preview from '@/views/hat/file/preview/index.vue';

window.preview = function(url) {
  Preview.createModal(url);
};

@Component({})
export default class TrajectoryMap extends Vue {
  @Prop({ type: String, default: '' }) color;
  bigScreen = false;
  AMap = null;
  circleMarker = [];
  hat = '';
  duration = 2000;
  time = '';
  location = '';
  avatar = '';
  circle = [
    [113.454903, 23.165857],
    [113.456347, 23.168226],
  ];
  zoneArray = [];
  mounted() {
    this.setHat();
    this.addMap();
  }
  handleScreen() {
    const bigScreen = this.$store.state.home.bigScreen;
    this.$store.commit('home/setBigScreen', !bigScreen);
  }
  beforeDestroy() {
    if (this.pathSimplifier) {
      this.pathSimplifier.setData([]);
    }
  }
  async addMap() {
    const AMap = await funAMap({
      AMapUI: {
        version: '1.1',
        plugins: ['misc/PathSimplifier'],
      },
    });
    this.AMap = AMap;
    const { mode } = this.$store.state.crossStorage.skin;
    this.map = new AMap.Map('boxMap', {
      zoom: 15,
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
    });
    this.map.on('complete', () => {
      this.$emit('map-loaded');
    });
  }
  navigator = undefined;
  pathSimplifier = undefined;
  photoPath(abPath) {
    return abPath + '?x-oss-process=image/resize,w_76,h_76,m_lfit';
  }
  videoPath(path) {
    const suffix = `?x-oss-process=video/snapshot,t_1000,f_jpg,w_76,h_76,m_fast`;
    return path + suffix;
  }
  async createPath(data) {
    if (this.pathSimplifier) {
      this.pathSimplifier.setData([]);
    }
    const { path, time, avatar } = data;
    data.result.forEach(v => {
      v.uid = uuid();
    });
    const filterResources = data.result.filter(
      v => v.type === 'PHOTO' || v.type === 'VIDEO' || v.type === 'AUDIO',
    );
    const allFilters = [];
    filterResources.forEach(v => {
      if (v.type === 'PHOTO') {
        v.imgUrl = this.photoPath(v.content);
        allFilters.push({
          ...v,
          imgUrl: v.content,
          different: 'different',
        });
      } else if (v.type === 'VIDEO') {
        v.imgUrl = this.videoPath(v.content);
        allFilters.push({
          ...v,
          imgUrl: v.content,
          different: 'different',
        });
      } else {
        v.imgUrl = v.content;
      }
      allFilters.push(v);
    });
    await this.fetchStaticResources(allFilters);
    allFilters.forEach(v => {
      const item = data.result.find(i => i.uid === v.uid);
      if (item) {
        if (v.different) {
          item.content = v.imgUrl;
        } else {
          item.imgUrl = v.imgUrl;
        }
      }
    });

    // 提前加载图片
    const _avatar = Array.from(new Set(avatar));
    for await (const iterator of _avatar) {
      await new Promise((resolve, reject) => {
        const img = new Image();
        img.src = iterator.indexOf('https') > -1 ? iterator : this.hat;
        img.onload = () => {
          resolve(img);
        };
        img.onerror = () => {
          reject(img);
        };
      });
    }

    if (path.length > 0) {
      // 路径距离计算
      let distance = 0;
      data.result.forEach((_, idx) => {
        if (idx >= 1) {
          distance = this.AMap.GeometryUtil.distance(
            [path[idx - 1][0], path[idx - 1][1]],
            [path[idx][0], path[idx][1]],
          );
          data.result[idx].cumulativeDistance =
            Number(distance.toFixed(0)) +
            data.result[idx - 1].cumulativeDistance;
        }
      });

      this.location = `${path[0][0]},${path[0][1]}`;
      this.time = time[0];
      this.avatar = avatar[0];
      [this.navigator, this.pathSimplifier] = await createPathSimplifier(
        this.map,
        path,
        idx => {
          let position = [path[idx][0], path[idx][1]];
          if (data.result && data.result.length > 0) {
            this.formatType({
              id: data.result[idx].content,
              type: data.result[idx].type,
              position,
              result: data.result[idx],
            });
          }
          this.location = `${path[idx][0]},${path[idx][1]}`;
          this.time = time[idx];
          this.avatar = avatar[idx];
          if (idx === path.length - 1) {
            this.stop();
            this.isFirstPlay = true;
            this.$emit('stop-play');
          }
          // 轨迹头像实时变化
          // let previousIdx;
          // if (avatar[idx] !== avatar[previousIdx]) {
          //   previousIdx = idx;
          //   // 覆盖修改
          //   this.extend(this.navigator.getStyleOptions(), {
          //     width: 35,
          //     height: 35,
          //     content: (ctx, x, y, width, height) => {
          //       const r = width / 2;
          //       const renderArcImg = (img, x, y, r) => {
          //         ctx.save();
          //         ctx.arc(x + r, y + r, r, 0, 2 * Math.PI);
          //         ctx.clip();
          //         ctx.drawImage(img, x, y, width, height);
          //         ctx.restore();
          //       };
          //       const img = document.createElement('img');
          //       img.src =
          //         avatar[idx].indexOf('https') > -1 ? avatar[idx] : this.hat;
          //       img.onload = () => {
          //         renderArcImg(img, x, y, r);
          //       };
          //     },
          //   });
          //   // 重新绘制
          //   this.pathSimplifier.renderLater();
          // }
        },
        {
          // content: this.avatar.indexOf('https') > -1 ? this.avatar : this.hat,
          content: this.hat,
          width: 35,
          height: 35,
        },
      );
    }
  }
  extend(dst) {
    if (!dst) {
      dst = {};
    }
    let slist = Array.prototype.slice.call(arguments, 1);
    for (let i = 0, len = slist.length; i < len; i++) {
      let source = slist[i];
      if (!source) {
        continue;
      }
      for (let prop in source) {
        // eslint-disable-next-line no-prototype-builtins
        if (source.hasOwnProperty(prop)) {
          dst[prop] = source[prop];
        }
      }
    }
    return dst;
  }
  async createTracePath2(data) {
    this.isFirstPlay = true; // 初始化播放状态
    await this.createPath(data);
    await this.setSpeed(this.speed);
  }
  formatType(opts) {
    this.TypePosition(opts);
    switch (opts.type) {
      // case 'POSITION':
      //   this.TypePosition(opts);
      //   break;
      case 'EVENT':
        this.TypeEvent(opts);
        break;
      case 'PHOTO':
        this.TypePhoto(opts);
        break;
      case 'VIDEO':
        this.TypeVideo(opts);
        break;
      case 'AUDIO':
        this.TypeAudio(opts);
        break;
      case 'IN_GEOFENCE':
      case 'OUT_GEOFENCE':
        this.TypeFence(opts);
        break;
    }
  }
  TypePosition(opts) {
    this.addDistanceMarker({
      ...opts,
      content: this.distanceHTML(opts.result),
    });
  }
  TypeEvent(opts) {
    this.addTemplateMarker(
      {
        ...opts,
        content: this.eventMarkerHTML(opts.result),
      },
      this.eventMarker,
    );
  }
  TypePhoto(opts) {
    this.addTemplateMarker(
      {
        ...opts,
        content: this.videoAndPhotoMarkerHTML(opts.result),
      },
      this.videoAndPhotoMaker,
    );
  }
  TypeVideo(opts) {
    this.addTemplateMarker(
      {
        ...opts,
        content: this.videoAndPhotoMarkerHTML(opts.result),
      },
      this.videoAndPhotoMaker,
    );
  }
  TypeAudio(opts) {
    this.addTemplateMarker(
      {
        ...opts,
        content: this.audioMarkerHTML(opts.result),
      },
      this.audioMarker,
    );
  }
  TypeFence(opts) {
    this.addFenceMarker({
      ...opts,
      content: this.fenceMarkerHTML(),
    });
  }
  async fetchStaticResources(opts) {
    const customService = new UploadService('/oss/iot/oss');
    const list = await customService.getAuth(opts.map(x => x.imgUrl));
    opts.forEach((x, index) => {
      x.imgUrl = list[index];
    });
  }
  async fetchSingleResource(url) {
    const customService = new UploadService('/oss/iot/oss');
    return await customService.getAuth(url);
  }
  distanceMarker = null;
  addDistanceMarker(opts) {
    if (this.distanceMarker) {
      this.distanceMarker.setPosition(opts.position);
      this.distanceMarker.setContent(opts.content);
    } else {
      this.distanceMarker = new this.AMap.Marker({
        map: this.map,
        position: opts.position,
        content: opts.content,
        offset: [-10, 20],
      });
    }
  }
  distanceHTML(opts) {
    let distance = '';
    if (opts.cumulativeDistance < 1000) {
      distance = `${opts.cumulativeDistance} 米`;
    } else {
      distance = `${(opts.cumulativeDistance / 1000).toFixed(1)} 公里`;
    }
    return `
    <style>
      .distanceHTMLBox {
        background: var(--font-active);
        padding: 2px 4px;
        font-size: 12px;
        color: #fff;
        border-radius: 10px;
        white-space: nowrap;
      }
    </style>
    <div class="distanceHTMLBox">
      ${distance} 
    </div>`;
  }
  eventMarker = null;
  audioMarker = null;
  videoAndPhotoMaker = null;
  addTemplateMarker(opts, Marker) {
    if (Marker) {
      Marker.setPosition(opts.position);
      Marker.setContent(opts.content);
    } else {
      Marker = new this.AMap.Marker({
        map: this.map,
        position: opts.position,
        content: opts.content,
        offset: [-20, -100],
      });
    }
    // 清除回调
    setTimeout(() => {
      if (Marker) {
        this.map.remove(Marker);
        Marker = null;
      }
    }, 2000);
  }
  eventMarkerHTML(opts) {
    return `
    <style>
      .eventMarkerHTMLBox {
        white-space: nowrap;
      }
      .txtEventHtml {
        background: var(--font-active);
        padding: 6px 4px;
        font-size: 12px;
        color: #fff;
        border-radius: 4px;
        width: 76px;
        display: block;
        text-align: center;
      }
    </style>
    <div class="eventMarkerHTMLBox">
      <span class="txtEventHtml">${opts.content}</span>
    </div>`;
  }
  videoAndPhotoMarkerHTML(opts) {
    return `
    <style>
      .eventMarkerHTMLBox {
        white-space: nowrap;
      }
      .txtEventHtml {
        background: var(--font-active);
        padding: 6px 4px;
        font-size: 12px;
        color: #fff;
        border-radius: 0 0 4px 4px;
        width: 76px;
        display: block;
        text-align: center;
      }
      .eventMarkerImgBox {
        width: 76px;
        font-size: 0;
        position: relative;
      }
      .eventMarkerImg {
        border-radius: 4px 4px 0 0;
        width: 100%;
      }
      .markerImgIconBox {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 22px;
        height: 22px;
        text-align: center;
        line-height: 22px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        color: #fff;
      }
    </style>
    <div class="eventMarkerHTMLBox" onclick="preview('${opts.content}')">
      <div class="eventMarkerImgBox">
        ${
          opts.type === 'VIDEO'
            ? `<div class="markerImgIconBox"><i aria-label="图标: caret-right" class="anticon anticon-caret-right"><svg viewBox="0 0 1024 1024" data-icon="caret-right" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class="" data-darkreader-inline-fill="" style="--darkreader-inline-fill:currentColor;"><path d="M715.8 493.5L335 165.1c-14.2-12.2-35-1.2-35 18.5v656.8c0 19.7 20.8 30.7 35 18.5l380.8-328.4c10.9-9.4 10.9-27.6 0-37z"></path></svg></i></div>`
            : ''
        }
        <img class="eventMarkerImg" src="${opts.imgUrl}" />  
      </div>
      <span class="txtEventHtml">${
        opts.type === 'PHOTO' ? '图片' : '视频'
      }</span>
    </div>`;
  }
  audioMarkerHTML(opts) {
    return `
    <style>
      .eventMarkerHTMLBox {
        white-space: nowrap;
      }
      .textIcon {
        font-size: 70px;
      }
      .textIconBox {
        display: block;
        padding: 2px;
        background-color: #fff;
        border-radius: 4px;
      }
      .voiceBox {
        display: block;
      }
    </style>
    <div class="eventMarkerHTMLBox">
      <div class="voiceBox" onclick="preview('${opts.imgUrl}')">
        <span class="textIconBox">
          <i class="anticon x-icon textIcon" data-type="tc-color-file-voice"><svg width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class="" data-darkreader-inline-fill="" style="--darkreader-inline-fill:currentColor;"><use xlink:href="#tc-color-file-voice"></use></svg></i>
        </span>
      </div>
    </div>`;
  }
  fenceMarker = null;
  fenceTextMarker = null;
  async addFenceMarker(opts) {
    if (opts.type === 'OUT_GEOFENCE') return false;
    if (this.fenceTextMarker) {
      this.fenceTextMarker.setPosition(opts.position);
      this.fenceTextMarker.setContent(opts.content);
    } else {
      this.fenceTextMarker = new this.AMap.Marker({
        map: this.map,
        position: opts.position,
        content: opts.content,
        offset: [-20, -100],
      });
    }
    if (this.fenceMarker !== null) return false;
    const array = await getZonesData({
      geofenceId: opts.id,
    });
    this.zoneArray = formatToApi(array);
    this.fenceMarker = drawCharts(this.zoneArray, this.AMap, () => {});
    this.map.add(this.fenceMarker);
    // 清除回调
    setTimeout(() => {
      if (this.fenceTextMarker && this.fenceMarker) {
        this.map.remove([this.fenceTextMarker, ...this.fenceMarker]);
        this.fenceTextMarker = null;
        this.fenceMarker = null;
      }
    }, 2000);
  }
  fenceMarkerHTML() {
    return `
    <style>
      .fenceMarkerHTMLBox {
        white-space: nowrap;
      }
      .txtFenceHtml {
        background: var(--font-active);
        padding: 6px 4px;
        font-size: 12px;
        color: #fff;
        border-radius: 4px;
        display: block;
        text-align: center;
      }
      
    </style>
    <div class="fenceMarkerHTMLBox">
      <span class="txtFenceHtml">进入围栏 </span>
    </div>`;
  }
  addCircle() {
    this.circleMarker.forEach(item => {
      item.setMap(this.map);
    });
  }
  deleteCircle() {
    this.circleMarker.forEach(item => {
      this.map.remove(item);
    });
  }
  isFirstPlay = true;
  play() {
    if (this.isFirstPlay) {
      this.navigator.start();
      this.isFirstPlay = false;
    } else {
      this.navigator.resume();
    }
  }
  stop() {
    this.navigator.pause();
  }
  iStop(sleep = 2000) {
    if (sleep) {
      setTimeout(() => {
        this.play();
      }, sleep);
    }
    this.navigator.stop();
  }
  speed = 1;
  setSpeed(duration) {
    this.speed = duration;
    const speed = INIT_SPEED * parseFloat(duration);
    this.navigator.setSpeed(speed);
  }
  async handleFenceChange(id, listFence) {
    const item = listFence.find(v => v.geofenceId === id);
    if (item.point && item.point.lng) {
      this.map.setCenter([item.point.lng, item.point.lat]);
    }
    const array = await getZonesData({
      geofenceId: id,
    });
    this.zoneArray = formatToApi(array);
    const list = drawCharts(this.zoneArray, this.AMap, (a, b) =>
      mouseEvent(a, b, this.map),
    );
    list.forEach(item => {
      this.circleMarker.push(item);
    });
    this.map.add(list);
  }
  setHat() {
    this.hat = {
      WHITE: whitehat,
      BLUE: bluehat,
      YELLOW: yellowhat,
      RED: redhat,
      ORANGE: orangehat,
    }[this.color];
  }
}
</script>

<style lang="less" module>
.mapWrap {
  position: relative;
  height: 100%;
  width: 100%;
  .screen {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99;
  }
  .icon {
    font-size: 30px;
    color: var(--primary);
  }
  .boxMap {
    height: 450px;
  }

  .time {
    height: 25px;
    width: 440px;
    display: flex;
    background-color: #ffffff;
    position: absolute;
    right: 10px;
    bottom: 5px;
    padding: 0px 10px;
    line-height: 25px;
    border-radius: 5px;
    span {
      height: 25px;
    }

    .ff {
      width: 180px;
    }

    .ll {
      width: 130px;
      text-align: left;
      padding-left: 10px;
    }

    .dd {
      width: 110px;
    }
  }
}
</style>
