<template>
  <div :class="$style.card">
    <div :class="$style.header">
      <div :class="[$style.avatarIcon, hatColor]">
        <x-oss-image
          :class="$style.iconImg"
          :ossPath="detail.userAvatar"
          v-if="detail.userAvatar"
        />
        <span :class="[$style.iconImg, $style.noAvatar]" v-else>{{
          detail.userName.charAt(0)
        }}</span>
      </div>
      <div :class="$style.title">
        <div :class="$style.name">
          <div :class="$style.textWrap">
            <div :class="[$style.text, $style.textOverflow]">
              {{ detail.userName || '-' }}
            </div>
          </div>

          <div :class="$style.web">
            <div :class="$style.net">
              <section
                :class="[
                  $style.signalWrap,
                  LOCATEMODE_KEY[detail.locateMode] === 'WIFI'
                    ? $style.mb14
                    : '',
                ]"
              >
                <span
                  :class="[
                    $style.netTxt,
                    LOCATEMODE_KEY[detail.locateMode] === 'WIFI'
                      ? $style.netTxtW
                      : '',
                  ]"
                  >{{ LOCATEMODE_KEY[detail.locateMode] }}</span
                >
                <a-icon
                  v-if="LOCATEMODE_KEY[detail.locateMode] === 'WIFI'"
                  type="wifi"
                  :class="$style.wifiIcon"
                />
                <div v-else :class="[$style.lnWrap, networkColor]">
                  <span :class="[$style.ln, $style.ln1]"></span>
                  <span :class="[$style.ln, $style.ln2]"></span>
                  <span :class="[$style.ln, $style.ln3]"></span>
                  <span :class="[$style.ln, $style.ln4]"></span>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div :class="$style.time">
          <span :class="$style.statusWrap">
            {{ computedSTATUS_KEY[detail.status] }}
          </span>
          <div>{{ time }}</div>
        </div>
      </div>
    </div>
    <section :class="$style.common">
      <div :class="$style.img" v-if="link">
        <img @click="handleShowMap" :src="link" alt="" :class="$style.map" />
        <div :class="[$style.address, $style.textOverflow]">
          <span>
            <a-tooltip placement="top">
              <span slot="title"> {{ address }} </span>
              {{ address }}
            </a-tooltip>
          </span>
        </div>
      </div>
      <div :class="$style.img" v-else @click="handleShowMap">
        <empty-content
          :class="$style.map"
          :label="$t('hat.deviceManagement.positionUnknown')"
        />
      </div>
      <div :class="$style.info">
        <div v-for="item in infoList" :key="item.title">
          <div style="color: var(--font-info)">{{ item.title }}</div>
          <div>{{ item.value }}</div>
        </div>
      </div>
      <div :class="$style.project">
        <div :class="$style.projectLabel">
          <div style="color: var(--font-info)">
            {{ $t('hat.managementModal.personInfo.project') }}
          </div>
          <div :class="$style.textOverflow">
            <a-tooltip placement="top">
              <span slot="title">{{ detail.projectName }}</span>
              {{ detail.projectName || '-' }}
            </a-tooltip>
          </div>
        </div>
        <div :class="$style.projectLabel">
          <div style="color: var(--font-info)">编组</div>
          <div :class="$style.textOverflow">
            <a-tooltip placement="top">
              <span slot="title">{{ detail.groupName }}</span>
              {{ detail.groupName || '-' }}
            </a-tooltip>
          </div>
        </div>
      </div>
      <div :class="[$style.info, $style.textOverflow]">
        <div>
          <div style="color: var(--font-info)">
            {{ $t('electricBoxMock.columns.equipemnt') }}
          </div>
          <div>
            {{ detail.deviceName }}
            <span :class="[$style.hatIcon, hatColor]">
              <span :class="$style.plusTxt" v-if="detail.type === 'PLUS'"
                >Plus</span
              >
              <x-icon v-else :class="$style.iconW" type="tc-icon-hat-smile" />
            </span>
          </div>
        </div>
      </div>
      <div :class="$style.voiceVdeio" v-if="detail.type === 'PLUS'">
        <div :class="$style.btn" @click="handleVideo('audio')">
          <div :class="$style.icon">
            <a-icon type="audio" theme="filled" />
          </div>
          <span>
            {{ $t('hat.managementModal.personInfo.voice') }}
          </span>
        </div>
        <a-dropdown>
          <div :class="$style.btn">
            <div :class="$style.icon">
              <a-icon type="video-camera" theme="filled" />
            </div>
            <span>
              {{ $t('hat.managementModal.personInfo.video') }}
            </span>
          </div>
          <a-menu slot="overlay">
            <a-menu-item @click="handleVideo('video')">
              {{ $t('hat.talk.call') }}
            </a-menu-item>
            <a-menu-item
              @click="handleMonitor"
              v-if="$p.action('IOT_HELMET_MONITOR_MODE', '/hat/device')"
            >
              {{ $t('hat.talk.patrol') }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </section>
  </div>
</template>
<script>
import {
  Component,
  Inject,
  InjectReactive,
  Vue,
  Watch,
} from 'vue-property-decorator';
import dayjs from 'dayjs';
import EmptyContent from '@/components/empty-content';
import { funAMap } from '@/views/hat/electron-fence/components/util';
import GroupTalk from '@/views/hat/device/components/group-talk.vue';
import { createFormModal, createModal } from '@triascloud/x-components';
import { queryDevice } from '@/services/smart-hat/device-management';
import MonitorConfirm from './monitor-confirm.vue';
import RtcDemo from './rtc-demo.vue';

/** @name 视频通话的模式：1普通；2无感巡查；3有感巡查 */
export const VIDEO_MODE = {
  /** @name 正常音视频通话 */
  NORMAL: 'NORMAL',
  /** @name 无感巡查 */
  SENSELESS_MONITORING: 'SENSELESS_MONITORING',
  /** @name 有感巡查 */
  SENSORY_MONITORING: 'SENSORY_MONITORING',
};

@Component({
  components: {
    EmptyContent,
  },
})
export default class PersonInfo extends Vue {
  @Inject('CHANGE_MINI_MAP') changeMapStatus;
  @InjectReactive('deviceDetail') detail;

  @Watch('detail', { immediate: true, deep: true })
  detailChange() {
    this.getDeviceDetail();
  }

  /** @name 设备联网方式 */
  LOCATEMODE_KEY = {
    FOUR_G: '4G',
    THREE_G: '3G',
    TWO_G: '2G',
    WIFI: 'WIFI',
  };
  /** @name 设备网络信号 */
  NETWORK_KEY = {
    DIFFERENCE: 'DIFFERENCE',
    MIDDLE: 'MIDDLE',
    GOOD: 'GOOD',
    EXCELLENT: 'EXCELLENT',
  };
  /** @name 定位卫星信号 */
  SATELLITE_KEY = {
    WEAK: this.$t('hat.managementModal.personInfo.weak'),
    STRONG: this.$t('hat.managementModal.personInfo.strong'),
  };
  /** @name 信号类型 */
  SIGNAL_KEY = {
    NOT: this.$t('hat.managementModal.personInfo.not'),
    GPS: 'GPS',
    BEIDOU: this.$t('hat.managementModal.personInfo.BDS'),
    GALILEO: this.$t('hat.managementModal.personInfo.galileo'),
    QUASI_ZENITH: this.$t('hat.managementModal.personInfo.QZSS'),
    DEAL: this.$t('hat.managementModal.personInfo.GLONASS'),
    HYBRID_POSITION: this.$t('hat.managementModal.personInfo.hybrid'),
  };
  POSITION_KEY = {
    BASE_STATION: this.$t('hat.managementModal.personInfo.baseStation'),
    WIFI: 'wifi',
  };
  get computedSTATUS_KEY() {
    const { $t } = this;
    return {
      UNACTIVE: $t('hat.managementModal.personInfo.unactived'),
      ONLINE: $t('hat.managementModal.personInfo.online'),
      OFFLINE: $t('hat.managementModal.personInfo.offline'),
      STANDBY: $t('hat.managementModal.personInfo.standby'),
      DISABLE: $t('hat.managementModal.personInfo.disable'),
      EXPIRED: $t('hat.managementModal.personInfo.expired'),
    };
  }
  get hatColor() {
    if (this.detail && this.detail.color) {
      switch (this.detail.color) {
        case 'WHITE':
          return this.$style.white;
        case 'RED':
          return this.$style.red;
        case 'YELLOW':
          return this.$style.yellow;
        case 'BLUE':
          return this.$style.blue;
        case 'ORANGE':
          return this.$style.orange;
      }
    }
    return '';
  }
  setSatellite() {
    const detail = this.detail;
    return this.POSITION_KEY[detail.positionType]
      ? this.POSITION_KEY[detail.positionType]
      : this.SIGNAL_KEY[detail.satelliteType] || '-';
  }
  handleShowMap() {
    let array = [];
    if (+this.detail?.longitude && +this.detail?.latitude) {
      array = [+this.detail.longitude, +this.detail.latitude];
    }
    if (array.length) {
      this.changeMapStatus(true, array);
    } else {
      this.changeMapStatus(true);
    }
  }

  async handleSingleTalk() {
    this.detail;
    let groupTalk = [];
    if (!this.beInvitedChannelId) {
      groupTalk = [
        {
          deviceUserId: 'DEVICE_' + this.detail.deviceName,
          deviceId: this.detail.deviceId,
          deviceName: this.detail.deviceName,
          avatar: this.detail.userAvatar,
          userName: this.detail.userName,
          status: '',
          enumMsg: '',
          isCall: false,
          resolution: 720,
          screenMode: 0,
          videoType: this.videoType,
          userType: 'DEVICE',
          muted: false,
          remoteVideoTrack: null,
          remoteAudioTrack: null,
          isFullScreen: false,
          lng: 0,
          lat: 0,
        },
      ];
    }
    const talkModal = createModal(
      () => (
        <GroupTalk
          arr={groupTalk}
          videoMode={this.videoMode}
          videoType={this.videoType}
          beInvitedChannelId={this.beInvitedChannelId}
          close={() => talkModal.handleClose()}
        />
      ),
      {
        width: '100vw',
        closable: false,
        wrapClassName: this.$style.groupTalkWrap,
      },
    );
  }
  videoType = 'video';
  async handleVideo(type) {
    if (this.detail.status !== 'ONLINE') {
      this.$message.warning('当前设备不在线！');
      return;
    }
    if (this.detail.type !== 'PLUS') {
      this.$message.warning('该版本不支持视频语音通话！');
      return;
    }
    this.videoType = type;
    if (type === 'audio' && this.detail.audioDemoPath) {
      this.handleTalkDemo(type);
      return;
    }
    if (type === 'video' && this.detail.videoDemoPath) {
      this.handleTalkDemo(type);
      return;
    }
    this.videoMode = VIDEO_MODE.NORMAL;
    const queryResult = await this.monitorCheck();
    if (!queryResult.status) {
      return false;
    }
    await this.handleSingleTalk();
  }
  /** @name 音视频通话示例 */
  handleTalkDemo(type) {
    const talkModal = createModal(
      () => (
        <RtcDemo
          close={() => talkModal.handleClose()}
          detail={this.detail}
          videoType={type}
        />
      ),
      {
        width: '100vw',
        closable: false,
        wrapClassName: this.$style.groupTalkWrap,
      },
    );
  }
  /** @name 设备是否巡查中... */
  async monitorCheck() {
    this.beInvitedChannelId = '';
    const data = await queryDevice(`DEVICE_${this.detail.deviceName}`);
    if (data && data.channelId) {
      this.beInvitedChannelId = data.channelId;
      if (
        data.mode === VIDEO_MODE.SENSELESS_MONITORING ||
        data.mode === VIDEO_MODE.SENSORY_MONITORING
      ) {
        this.videoMode = data.mode;
        if (!this.$p.action('IOT_HELMET_MONITOR_MODE', '/hat/device')) {
          this.$message.warning('设备通话中，请稍后再拨！');
          return {
            val: data,
            status: false,
          };
        }
        const checkResult = await createFormModal(
          () => (
            <div class={this.$style.mode1Wrap}>
              <p class={this.$style.mb}>{this.$t('hat.talk.tip.title')}</p>
              <p>{this.$t('hat.talk.tip.attention')}</p>
              <p>1、您的麦克风会默认关闭，开启后设备端可正常收听到您的语音；</p>
              <p>2、请确保设备所处场允许拍摄，且不侵犯现场人员的隐私；</p>
              <p>3、巡查模式需最新设备固件支持，否则将按正常通话处理；</p>
              <p>
                4、请确保使用场景的合规、合法性，三叠不对由此行为承担任何责任；
              </p>
            </div>
          ),
          {
            width: 550,
            title: '操作确认',
          },
        );
        if (checkResult) {
          return {
            val: data,
            status: true,
          };
        }
      } else {
        this.$message.warn('设备通话中，请稍后再拨！');
        return {
          val: data,
          status: false,
        };
      }
    }
    return {
      val: data,
      status: true,
    };
  }
  videoMode = VIDEO_MODE.NORMAL;
  beInvitedChannelId = '';
  async handleMonitor() {
    this.videoType = 'video';
    const queryResult = await this.monitorCheck();
    if (queryResult.status) {
      if (!queryResult.val) {
        const mode1 = await createFormModal(() => <MonitorConfirm />, {
          width: 550,
          title: '操作确认',
        });
        if (mode1) {
          this.videoMode = mode1;
        }
      }
      if (this.videoType === 'video' && this.detail.videoDemoPath) {
        this.handleTalkDemo();
        return;
      }
      await this.handleSingleTalk();
    }
  }

  get infoList() {
    return [
      {
        title: this.$t('hat.deviceManagement.details.panel.type'),
        value: this.setSatellite(),
      },
      {
        title: this.$t('hat.managementModal.personInfo.longitude'),
        value: +this.detail?.longitude || '-',
      },
      {
        title: this.$t('hat.managementModal.personInfo.latitude'),
        value: +this.detail?.latitude || '-',
      },
      {
        title: this.$t('hat.managementModal.personInfo.signal'),
        value: this.SATELLITE_KEY[this.detail.satelliteSignal] || '-',
      },
    ];
  }
  get computedInfoList() {
    const $t = this.$t;
    return [
      {
        title: $t('hat.managementModal.personInfo.satellite'),
        value: this.setSatellite(),
      },
      {
        title: $t('hat.managementModal.personInfo.longitude'),
        value: +this.detail?.longitude || '-',
      },
      {
        title: $t('hat.managementModal.personInfo.latitude'),
        value: +this.detail?.latitude || '-',
      },
      {
        title: $t('hat.managementModal.personInfo.signal'),
        value: this.SATELLITE_KEY[this.detail.satelliteSignal] || '-',
      },
    ];
  }

  time = '';
  async getDeviceDetail() {
    this.getStaticMap();
    if (this.detail.lastOnlineTime) {
      this.time = this.timeFormat(Date.now(), this.detail.lastOnlineTime);
    }
    await this.getAddressByLngLat();
  }

  // 秒、分钟、小时、天
  timeFormat(current = 0, target = 0) {
    /** @name 1天的毫秒数 */
    const diffDay = 86400000;
    /** @name 1小时的毫秒数 */
    const dH = 3600000;
    /** @name 1分钟的毫秒数 */
    const dM = 60000;

    const diff = current - target;

    let result = '';
    if (diff >= diffDay) {
      const day = Math.floor(diff / diffDay);
      result = this.$t('hat.managementModal.personInfo.onlineTime', {
        value: day,
        unit: this.$t('screen.days'),
      });
    } else if (diff > dH) {
      const hour = dayjs(current)
        .diff(target, 'hour', true)
        .toFixed(1);
      result = this.$t('hat.managementModal.personInfo.onlineTime', {
        value: hour,
        unit: this.$t('hat.screen.hours'),
      });
    } else if (diff > dM) {
      const minute = dayjs(current).diff(target, 'minute');
      result = this.$t('hat.managementModal.personInfo.onlineTime', {
        value: minute,
        unit: this.$t('hat.managementModal.setting.hint.rule.minutes'),
      });
    } else {
      result = this.$t('hat.managementModal.personInfo.onlineTime', {
        value: '1',
        unit: this.$t('hat.managementModal.setting.hint.rule.minutes'),
      });
    }
    return result;
  }

  link = '';
  getStaticMap() {
    if (+this.detail?.longitude && +this.detail?.latitude) {
      const hatColor = {
        WHITE: 'ffffff',
        RED: 'f60000',
        YELLOW: 'ffbf07',
        BLUE: '4771ff',
        ORANGE: 'ff8707',
      };
      const color = hatColor[this.detail.color];
      const LngLat = `${+this.detail.longitude},${+this.detail.latitude}`;
      const link = `https://restapi.amap.com/v3/staticmap?location=${LngLat}&zoom=16&size=305*160&markers=small,0x${color},:${LngLat}&key=${process.env.VUE_APP_AMAP_WEB_API_KEY}`;
      this.link = link;
    }
  }

  address = '';
  async getAddressByLngLat() {
    if (this.detail.longitude) {
      const AMap = await funAMap({
        plugins: ['AMap.Geocoder'],
      });
      const geocoder = new AMap.Geocoder();

      geocoder.getAddress(
        [this.detail.longitude, this.detail.latitude],
        (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            this.address = result.regeocode.formattedAddress;
          }
        },
      );
    }
  }
  get networkColor() {
    if (this.detail.networkSignal === this.NETWORK_KEY.DIFFERENCE) {
      return this.$style.a1;
    } else if (this.detail.networkSignal === this.NETWORK_KEY.MIDDLE) {
      return this.$style.a2;
    } else if (this.detail.networkSignal === this.NETWORK_KEY.GOOD) {
      return this.$style.a3;
    } else {
      return this.$style.a4;
    }
  }
}
</script>
<style lang="less" module>
.card {
  box-shadow: 0 0 6px 0 #e1e1e1;
  border-radius: 6px;
  padding: 0 20px;
}

.header {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .avatarIcon {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .iconImg {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      object-fit: cover;
    }
    .noAvatar {
      color: #fff;
      background-color: var(--x-modal-select-avatar);
      text-align: center;
      line-height: 42px;
      font-weight: 800;
      font-size: 18px;
    }
  }

  .title {
    flex: 1;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 240px;
      .textWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .text {
        max-width: 100px;
        font-size: 20px;
        font-weight: 600;
      }
      .status {
        box-sizing: content-box;
        font-size: 12px;
        color: white;
        background-color: var(--primary);
        border-radius: 10px;
        text-align: center;
        margin-left: 10px;
        padding: 1px 10px;
        display: inline-block;
      }
      .web {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .net {
          color: var(--primary);
          display: flex;
          align-items: center;
        }
      }
    }

    .time {
      width: 200px;
      display: flex;
      align-items: center;
      color: var(--font-info);
      margin-top: 2px;
      .statusWrap {
        padding: 0 4px;
        border-radius: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        font-size: 10px;
        color: #fff;
        background-color: var(--font-active);
        .iconW {
          color: #ffffff;
          font-size: 14px;
        }
      }
    }
  }
}
.hatIcon {
  width: 32px;
  height: 16px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-shadow: 0 0 3px 1px rgba(51, 51, 51, 0.5);
  vertical-align: sub;
  .iconW {
    color: #ffffff;
    font-size: 14px;
  }
}

.common {
  .voiceVdeio {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;

    .btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 85px;
      font-size: 16px;
      cursor: pointer;

      .icon {
        height: 42px;
        width: 42px;
        border-radius: 50%;
        background-color: var(--tag);
        color: var(--font);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.img {
  position: relative;
  margin-top: 20px;

  .map {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    box-shadow: 0 0 3px 0 #e1e1e1;
  }

  .address {
    position: absolute;
    bottom: 0;
    color: var(--block-bg);
    width: 100%;
    padding: 2px 0 2px 4px;
    background: rgba(51, 51, 51, 0.5);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.info {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.project {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .projectLabel {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.textOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.blue {
  background-color: #4771ff;
}
.yellow {
  background-color: #ffbf07;
}
.white {
  // background-color: #f3f6fc;
  background-color: #fff;
  .iconW {
    color: #fff !important;
  }
  .plusTxt {
    color: var(--font-sub);
  }
}
.red {
  background-color: #f60000;
}
.orange {
  background-color: #ff8707;
}

.signalWrap {
  display: inline-block;
  height: 20px;
  position: relative;
  &.mb14 {
    margin-bottom: -14px;
  }
  .wifiIcon {
    font-size: 20px;
  }
  .netTxtW {
    top: -14px !important;
  }
  .netTxt {
    position: absolute;
    top: -4px;
    left: 0;
    font-size: 10px;
  }
  .sWicon {
    fill: var(--font-active);
    width: 20px;
    height: 20px;
  }
  .sIcon {
    border-radius: 4px;
    display: inline-flex;
    width: 3px;
    height: 1px;
    background-color: var(--font-active);
  }
}
.plusTxt {
  font-size: 20px;
  font-weight: bolder;
  transform: scale(0.5);
  margin: 0 !important;
  color: #ffffff;
}
.mode1Wrap {
  font-size: 14px;
  color: var(--font);
  p {
    font-size: 12px;
    color: var(--font-sub);
  }
  .mode1Tip {
    font-size: 12px;
    margin-left: 66px;
    color: var(--font-sub);
    margin-bottom: 12px;
  }
  .mode1Top {
    margin-bottom: 4px;
  }
  .mode1Title {
    font-size: 14px;
    margin-right: 10px;
  }
  .mb {
    font-size: 16px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
}
.groupTalkWrap {
  :global {
    .ant-modal-header {
      padding: 0;
      border: none;
      background: transparent;
      border-radius: 0;
    }
    .ant-modal-content {
      height: 100vh;
      max-height: 100vh;
      max-width: 100vw;
      border-radius: 0;
    }
    .ant-modal-body {
      padding: 0;
      margin: 0;
    }
  }
}
.lnWrap {
  display: flex;
  transform: rotateX(180deg);
  &.a1 {
    .ln1 {
      background-color: var(--font-active);
    }
  }
  &.a2 {
    .ln1,
    .ln2 {
      background-color: var(--font-active);
    }
  }
  &.a3 {
    .ln1,
    .ln2,
    .ln3 {
      background-color: var(--font-active);
    }
  }
  &.a4 {
    .ln1,
    .ln2,
    .ln3,
    .ln4 {
      background-color: var(--font-active);
    }
  }
  .ln {
    width: 6px;
    height: 6px;
    background-color: var(--primary-30);
    display: inline-block;
    border-radius: 4px;
  }
  .ln + .ln {
    margin-left: 2px;
  }
  .ln2 {
    height: 10px;
  }
  .ln3 {
    height: 15px;
  }
  .ln4 {
    height: 20px;
  }
}
</style>
