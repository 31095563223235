import request from '../request';

const serviceName = '/iot-smart-helmet/device';
const groupService = '/iot-smart-helmet/group';
const deviceScreen = '/iot-smart-helmet/screen';
const projectService = '/iot-smart-helmet/pm';

export function getDeviceList(data) {
  return request(`${serviceName}/list`, {
    method: 'GET',
    body: data,
  });
}

export function addDevice(data) {
  return request(`${serviceName}`, {
    method: 'POST',
    body: data,
  });
}

export function putDevice(data) {
  return request(`${serviceName}`, {
    method: 'PUT',
    body: data,
  });
}

export function deleteDevice(deviceId) {
  return request(`${serviceName}/${deviceId}`, {
    method: 'DELETE',
  });
}

export function exportDeviceExcel() {
  return request(`${serviceName}/exportDeviceExcel`, {
    method: 'GET',
  });
}

export function putBinding(data) {
  return request(`${serviceName}/bind`, {
    method: 'PUT',
    body: data,
  });
}

export function getDeviceStatistics() {
  return request(`${serviceName}/count`, {
    method: 'GET',
  });
}

export function addProject(data) {
  return request(`${projectService}`, {
    method: 'POST',
    body: data,
  });
}

export function getProjectList(data) {
  return request(`${projectService}/pagePm`, {
    method: 'GET',
    body: data,
  });
}

export function deleteProject(id) {
  return request(`${projectService}/${id}`, {
    method: 'DELETE',
  });
}

export function getUserList(idxProjectId) {
  return request(`/smart-site/project-member/list`, {
    method: 'GET',
    body: { idxProjectId },
  });
}

/** @name 编组接口 */
export function getGroupList(data) {
  return request(`${groupService}/pageByName`, {
    method: 'GET',
    body: data,
  });
}

export function getGroupById(id) {
  return request(`${groupService}/${id}`, {
    method: 'GET',
  });
}

export function addGroup(data) {
  return request(`${groupService}`, {
    method: 'POST',
    body: data,
  });
}

export function putGroup(data) {
  return request(`${groupService}`, {
    method: 'PUT',
    body: data,
  });
}

export function deleteGroup(id) {
  return request(`${groupService}/${id}`, {
    method: 'DELETE',
  });
}

/** @name 事件明细 */
export function getEventList(data) {
  return request(`${serviceName}/message`, {
    method: 'GET',
    body: data,
  });
}

/** @name 事件统计 */
export function getEventStatistics(data) {
  return request(`${serviceName}/message/countReport`, {
    method: 'GET',
    body: data,
  });
}

/** @name 管理-设置接口 */
export function getRuleList(deviceId) {
  return request(`${serviceName}/reminder/${deviceId}`, {
    method: 'GET',
  });
}

export function putRule(data) {
  return request(`${serviceName}/reminder`, {
    method: 'PUT',
    body: data,
  });
}

export function batchUpdateRule(data) {
  return request(`${serviceName}/reminder/batchUpdate`, {
    method: 'PUT',
    body: data,
  });
}

export function resetDevice(deviceId) {
  return request(`${serviceName}/resetDevice/${deviceId}`, {
    method: 'GET',
  });
}

export function putFrequency(data) {
  return request(`${serviceName}/frequency`, {
    method: 'PUT',
    body: data,
  });
}

export function putOtherSetting(data) {
  return request(`${serviceName}/otherSetting`, {
    method: 'PUT',
    body: data,
  });
}

/** @name OTA升级接口 */
export function executeOtaUpgrade(data) {
  return request(`${serviceName}/ota/executeOtaUpgrade`, {
    method: 'PUT',
    body: data,
  });
}

export function deviceOtaUpgrade(deviceId) {
  return request(`${serviceName}/ota/deviceOtaUpgrade/${deviceId}`, {
    method: 'GET',
  });
}

/** @name 获取项区域统计 */
export function areaMap(data) {
  return request(`${deviceScreen}/count`, {
    method: 'GET',
    body: data,
  });
}

/** @name 获取地图设备列表 */
export function getMapDeviceList(data) {
  return request(`${deviceScreen}/list`, {
    method: 'GET',
    body: data,
  });
}

/** @name 音视频-发起通话 */
export function initiateCall(data) {
  return request(`${serviceName}/call/initiate`, {
    method: 'POST',
    body: data,
  });
}

// /** @name 音视频-结束通话 */
// export function finishCall(channelId) {
//   return request(`${serviceName}/call/finish/${channelId}`, {
//     method: 'POST',
//   });
// }

/** @name 设备数据更新 */
export function updateDeviceData(data) {
  return request(`${serviceName}/updateDeviceData`, {
    method: 'POST',
    body: data,
  });
}

/** @name 创建房间（监控） */
export function createRoomByMonitor(data) {
  return request(`${serviceName}/call/monitoring`, {
    method: 'POST',
    body: data,
  });
}
/** @name 创建房间（语音通话） */
export function createRoomByNormal(data) {
  return request(`${serviceName}/call/normal`, {
    method: 'POST',
    body: data,
  });
}
/** @name 退出频道 */
export function leaveChannel(data) {
  return request(`${serviceName}/call/leave?${data}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

/** @name 查询指定用户所在频道（设备码或用户ID） */
export function queryDevice(id) {
  return request(`${serviceName}/call/userInChannel/${id}`, {
    method: 'GET',
  });
}
/** @name 查询指定用户所在频道（通话用户ID集合） */
export function queryDevices(ids) {
  return request(`${serviceName}/call/usersInChannel?userIds=${ids}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}
/** @name 邀请加入房间（语音通话） */
export function inviteByNormal(data) {
  return request(`${serviceName}/call/invite`, {
    method: 'POST',
    body: data,
  });
}
/** @name 邀请加入房间（监控） */
export function inviteByMonitor(data) {
  return request(`${serviceName}/call/monitoring/invite`, {
    method: 'POST',
    body: data,
  });
}
/** @name 同意加入房间 */
export function agree(data) {
  return request(`${serviceName}/call/agree?${data}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}
/** @name 关闭房间(多人通话功能预留) */
export function closeRoom(channelId) {
  return request(`${serviceName}/call/close/${channelId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

/** @name 获取用量统计 */
export function getUsageStatistics(data) {
  return request(`${serviceName}/usageStatistics`, {
    method: 'GET',
    body: data,
  });
}

/** @name 获取短信明细列表 */
export function getMessageDetailList(data) {
  return request(`${serviceName}/pageSmsDetailList`, {
    method: 'GET',
    body: data,
  });
}

/** @name 获取通话明细列表 */
export function getRtcDetailList(data) {
  return request(`${serviceName}/pageRtcCalDetailList`, {
    method: 'GET',
    body: data,
  });
}

/** @name 获取设备管理详情用量统计 */
export function getDeviceUsageStatistics(data) {
  return request(`${serviceName}/ignore/usageStatistics`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @name 修改演示音视频地址
 */
export function putRtcDemo(data) {
  return request(`${serviceName}/demoFile`, {
    method: 'PUT',
    body: data,
  });
}
