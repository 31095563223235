<template>
  <div :class="$style.wrap">
    <div :class="$style.content">
      <video
        id="video"
        :src="path"
        :muted="muted"
        :controls="false"
        :class="$style.video"
        autoplay
        loop
        v-if="videoType === 'video' && isCall"
      />
      <div :class="$style.audioAvatar">
        <x-oss-image
          :class="$style.avatar"
          :ossPath="detail.userAvatar"
          v-if="detail.userAvatar && videoType === 'audio'"
        />
        <img
          :class="$style.avatar"
          src="@/assets/img/default-avatar.png"
          v-if="!detail.userAvatar && videoType === 'audio'"
        />
        <div :class="$style.loadText" v-if="videoType === 'audio' || !isCall">
          {{
            isCall
              ? '通话中'
              : `网络${videoType === 'audio' ? '电话' : '视频'}正在接通中`
          }}
          <div v-if="!isCall" :class="$style.spinner">
            <div :class="$style.bounce1"></div>
            <div :class="$style.bounce2"></div>
            <div :class="$style.bounce3"></div>
          </div>
        </div>
      </div>
      <!-- 操作栏 -->
      <ul :class="[$style.iconWrap, $style.activeWrap]">
        <li :class="$style.icon">
          <span :class="[$style.iconBg, $style.iconBgRed]" @click="close">
            <a-icon :class="$style.aIcon" type="close" />
          </span>
          <span :class="$style.iconTxt">挂断</span>
        </li>
        <li :class="$style.icon">
          <span
            :class="[$style.iconBg, muted ? '' : $style.iconBgActive]"
            @click.stop="handleSound"
          >
            <div :class="$style.line" v-if="muted"></div>
            <a-icon :class="$style.aIcon" type="sound" />
          </span>
          <span :class="$style.iconTxt">扬声器</span>
        </li>
        <!-- <li :class="$style.icon" v-if="videoType === 'video'">
          <span
            :class="$style.iconBg"
            @click.stop="handleCapturePicture(`#${deviceUserId} video`)"
          >
            <a-icon :class="$style.aIcon" type="scissor" />
          </span>
          <span :class="$style.iconTxt">截图</span>
        </li> -->
      </ul>
      <!-- 地图 -->
      <div
        :class="$style.map"
        :style="`transform: translateY(${showMap ? '-260px' : '260px'});`"
      >
        <position-map :deviceId="detail.deviceId" />
      </div>
      <!-- 头像 -->
      <div :class="$style.avatarBox">
        <img
          :class="$style.avatar"
          src="@/assets/img/default-avatar.png"
          v-if="!detail.userAvatar"
        />
        <x-oss-image
          v-else
          :class="$style.avatar"
          :ossPath="detail.userAvatar"
        />
        <span :class="$style.text">{{ detail.userName }}</span>
        <x-icon
          :class="$style.aIcon"
          type="tc-icon-address"
          @click="openPositionMap"
          :style="`color: ${showMap ? 'var(--primary)' : 'gray'};`"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { capturePicture } from '../../../utils';
import PositionMap from '@/views/hat/device/components/position-map.vue';

@Component({
  components: { PositionMap },
})
export default class GroupTalk extends Vue {
  @Prop({ type: Function }) close;
  @Prop({ type: Object }) detail;
  @Prop({ type: String, default: 'video' }) videoType;

  muted = true;
  isCall = false;
  async mounted() {
    if (this.videoType === 'video') {
      this.playVideo();
    } else {
      this.playAudio();
    }
  }
  beforeDestroy() {
    clearTimeout(this.timeout);
    this.timeout = null;
    if (this.audio) {
      this.audio.pause();
      this.audio = null;
    }
  }
  path = '';
  timeout = null;
  playAudio() {
    this.timeout = setTimeout(() => {
      this.isCall = true;
      this.loadVoice();
    }, 2500);
  }
  playVideo() {
    this.path = this.detail.videoDemoPath;
    this.timeout = setTimeout(() => {
      this.isCall = true;
    }, 2500);
  }
  audio = null;
  loadVoice() {
    this.audio = new Audio(this.detail.audioDemoPath);
    this.audio.loop = true;
    this.audio.muted = this.muted;
    this.audio.play();
  }
  handleCapturePicture() {
    const video = document.getElementById('video');
    // TODO: 当摄像头可以调整角度后，这里的宽度和高度需要更改回来
    // capturePicture(video, rect.width, rect.height);
    capturePicture(video, 500, 500);
  }
  muted = true;
  handleSound() {
    this.muted = !this.muted;
    if (this.audio) {
      this.audio.muted = this.muted;
    }
  }
  showMap = false;
  openPositionMap() {
    this.showMap = !this.showMap;
  }
}
</script>
<style lang="less" module>
.wrap {
  height: 100%;
  background-color: var(--main-bg);
  padding: 10px;
  .content {
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: var(--font);
    border-radius: 10px;
    .video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .audioAvatar {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .avatar {
        width: 100px;
        height: 100px;
        border-radius: 100%;
      }
      .loadText {
        color: #fff;
        font-size: 14px;
        text-align: center;
        white-space: nowrap;
      }
    }
    .avatarBox {
      background-color: rgba(0, 0, 0, 0.5);
      height: 36px;
      border-radius: 95px;
      display: inline-flex;
      align-items: center;
      position: absolute;
      left: 20px;
      top: 10px;
      z-index: 1;
      white-space: nowrap;
    }
    .avatar {
      margin-left: 3px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .aIcon {
      font-size: 20px;
      color: #fff;
      margin-left: 10px;
      margin-right: 10px;
    }
    .text {
      font-size: 14px;
      margin-left: 4px;
      color: #fff;
    }
    .map {
      height: 260px;
      width: 400px;
      background-color: #fff;
      position: absolute;
      bottom: -260px;
      right: 0;
      z-index: 100;
      transition: all 0.3s;
    }
    .iconWrap {
      height: 102px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-radius: 12px;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 20px;
      background-color: rgba(255, 255, 255, 0.4);

      .icon {
        padding: 4px 0;
        list-style: none;
        margin-right: 40px;
        text-align: center;
        cursor: pointer;
        .iconBg {
          display: inline-block;
          position: relative;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          &.iconBgRed {
            background-color: #f45454;
            .aIcon {
              font-weight: bolder;
              font-size: 24px;
            }
          }
          &.iconBgActive {
            // background-color: rgba(255, 255, 255) !important;
            .aIcon {
              color: #48ff00;
            }
          }
          .aIcon {
            font-size: 20px;
            color: #fff;
          }
          .text {
            color: #fff;
            font-size: 14px;
          }
          .line {
            height: 60%;
            width: 1px;
            position: absolute;
            border: 1px solid #fff;
            transform-origin: 50%;
            transform: rotateZ(45deg);
          }
        }
        .iconTxt {
          font-size: 12px;
          color: #fff;
          cursor: default;
        }
        .setting {
          display: flex;
          flex-direction: column;
          .text {
            color: #fff;
            font-size: 10px;
            margin-top: 2px;
          }
          .screenIcon {
            color: #fff;
            font-size: 16px;
          }
        }
      }
      .icon:first-of-type {
        border-right: 1px solid rgba(255, 255, 255, 0.6);
        padding-right: 20px;
        margin-right: 20px;
      }
      .icon:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.spinner {
  width: 24px;
  text-align: center;
  display: inline-block;
}

.spinner > div {
  width: 8px;
  height: 8px;
  background-color: #ffffff;

  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>
