<template>
  <div :class="$style.notify">
    <a-form-model ref="ruleForm" layout="inline" :modle="form">
      <div :class="$style.buttonGroups">
        <a-button
          :class="$style.buttonItem"
          type="primary"
          @click="isEdit = true"
          v-if="!isEdit && $p.action('UPDATE')"
          >{{ $t('hat.deviceManagement.edit') }}</a-button
        >
        <a-button
          :class="$style.buttonItem"
          type="default"
          @click="isEdit = false"
          v-if="isEdit"
          >{{ $t('msg.cancel') }}</a-button
        >
        <a-button
          :class="$style.buttonItem"
          type="primary"
          @click="handleSave"
          v-if="isEdit"
          >{{ $t('msg.save') }}</a-button
        >
      </div>
      <a-col :class="$style.check">
        <span>{{ $t('hat.managementModal.setting.hint.other.geofence') }}</span>
      </a-col>
      <a-col :offset="1" :class="$style.check">
        <a-form-model-item prop="defaultLocation">
          <div slot="label" :class="$style.formLabel">
            {{ $t('hat.managementModal.setting.hint.other.default') }}
          </div>
          <a-select
            style="width: 250px"
            show-search
            allowClear
            v-model="form.defaultLocation"
            :placeholder="$t('hat.deviceManagement.form.select')"
            option-filter-prop="children"
            :filter-option="true"
            :disabled="!isEdit"
            allow-clear
          >
            <a-select-option
              v-for="item in fenceList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :class="$style.check">
        <span>{{ $t('hat.managementModal.setting.hint.other.sos') }}</span>
      </a-col>
      <a-col :offset="1" :class="$style.check">
        <a-form-model-item prop="noticeMemberType">
          <div
            slot="label"
            :class="$style.formLabel"
            :title="
              $t('hat.managementModal.setting.hint.other.emergencyContacts')
            "
          >
            {{ $t('hat.managementModal.setting.hint.other.emergencyContacts') }}
          </div>
          <a-radio-group
            v-model="form.noticeMemberType"
            :disabled="!isEdit"
            @change="handleChange"
          >
            <a-radio value="SYSTEM_MEMBER">
              <span>{{
                $t('hat.managementModal.setting.hint.other.fromMembers')
              }}</span>
            </a-radio>
            <a-radio value="GROUP">
              <span>{{
                $t('hat.managementModal.setting.hint.other.fromGroup')
              }}</span>
            </a-radio>
            <a-radio value="PHONE_NUMBER">
              <span>{{
                $t('hat.managementModal.setting.hint.other.phoneNumber')
              }}</span>
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-col>
      <a-col :class="$style.check" style="margin-left: 115px">
        <a-form-model-item prop="noticeMember">
          <div v-if="form.noticeMemberType === 'SYSTEM_MEMBER'">
            <x-member-select
              v-model="members"
              :view="!isEdit"
              :value="range"
              @input="updateRangeValue"
              :type="['org-user', 'role-user', 'user']"
              :limit="1"
              :multiple="true"
              style="width: 240px"
            />
          </div>
          <div v-if="form.noticeMemberType === 'GROUP'">
            <a-select
              style="width: 200px"
              show-search
              allowClear
              v-model="form.noticeMember"
              :placeholder="$t('hat.deviceManagement.form.select')"
              option-filter-prop="children"
              :filter-option="true"
              :disabled="!isEdit"
              allow-clear
            >
              <a-select-option
                v-for="item in groupList"
                :key="item.pkId"
                :value="item.pkId"
              >
                {{ item.deviceGroupName }}
              </a-select-option>
            </a-select>
          </div>
          <div v-if="form.noticeMemberType === 'PHONE_NUMBER'">
            <a-input
              :placeholder="$t('common.enterPhone')"
              v-model="form.noticeMember"
              :disabled="!isEdit"
              style="width: 200px"
            />
          </div>
        </a-form-model-item>
      </a-col>
      <a-col :offset="1" :class="$style.check">
        <a-form-model-item prop="noticeMemberType">
          <div
            slot="label"
            :class="$style.formLabel"
            :title="
              $t('hat.managementModal.setting.hint.other.notificationMethod')
            "
          >
            {{
              $t('hat.managementModal.setting.hint.other.notificationMethod')
            }}
          </div>
          <a-checkbox-group v-model="form.noticeMethodList" :disabled="!isEdit">
            <a-checkbox
              value="SYSTEM"
              :disabled="form.noticeMemberType === 'PHONE_NUMBER'"
            >
              <span :class="$style.marginLeft10">{{
                $t('hat.managementModal.setting.hint.other.siteNotifications ')
              }}</span>
            </a-checkbox>
            <a-checkbox value="SMS">
              <span :class="$style.marginLeft10">{{
                $t('hat.managementModal.setting.hint.other.sms')
              }}</span>
            </a-checkbox>
            <a-checkbox
              value="VOICE"
              :disabled="form.noticeMemberType === 'PHONE_NUMBER'"
            >
              <span :class="$style.marginLeft10">{{
                $t('hat.managementModal.setting.hint.other.helmetVoice')
              }}</span>
              <span :class="$style.desc">
                （{{
                  $t('hat.managementModal.setting.hint.other.helmetVoiceTips')
                }}）
              </span>
            </a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
      </a-col>
      <a-col :offset="1" :class="$style.check">
        <a-form-model-item prop="content">
          <div
            slot="label"
            :class="$style.formLabel"
            :title="$t('electricBox.mg.table.noticeCtx')"
          >
            {{ $t('electricBox.mg.table.noticeCtx') }}
          </div>
          <a-input
            v-model="form.content"
            :placeholder="$t('common.enter')"
            :disabled="!isEdit"
            style="width: 500px"
            id="input"
            @blur="onBlur"
          />
          <div :class="$style.tags" v-if="isEdit">
            <span @click="insertVariable('USER_NAME')">{{
              $t('hat.managementModal.setting.hint.other.name')
            }}</span>
            <span @click="insertVariable('SEND_TIME')">{{
              $t('hat.managementModal.setting.hint.other.triggerTime')
            }}</span>
            <span
              v-if="!form.noticeMethodList.includes('VOICE')"
              @click="insertVariable('LOCATION_URL')"
              >{{
                $t('hat.managementModal.setting.hint.other.locationLink')
              }}</span
            >
            <span @click="insertVariable('LOCATION_NAME')">{{
              $t('hat.managementModal.setting.hint.other.location')
            }}</span>
          </div>
        </a-form-model-item>
      </a-col>
      <a-col :class="$style.check">
        <span>{{
          $t('hat.managementModal.setting.hint.other.reminderTimes')
        }}</span>
      </a-col>
      <a-col :offset="1" :class="$style.check">
        <a-form-model-item prop="num">
          <div
            slot="label"
            :class="$style.formLabel"
            :title="$t('hat.managementModal.setting.hint.other.voicePlayback')"
          >
            {{ $t('hat.managementModal.setting.hint.other.voicePlayback') }}
          </div>
          <a-select
            style="width: 105px"
            v-model="form.num"
            :placeholder="$t('hat.deviceManagement.form.select')"
            :disabled="!isEdit"
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in 5"
              :key="index"
              :value="index + 1"
            >
              {{ index + 1 }}
            </a-select-option>
          </a-select>
          <span style="margin: 0 10px">{{
            $t('hat.managementModal.setting.hint.other.TimesPiece')
          }}</span>
        </a-form-model-item>
      </a-col>
      <a-row>
        <a-col style="margin-bottom: 10px">{{
          $t('hat.managementModal.setting.hint.debug.info')
        }}</a-col>
        <a-col :offset="1" :class="$style.deviceInfo">
          <p>
            {{ $t('hat.managementModal.setting.hint.debug.firmware') }}：{{
              deviceInfo.firmwareVersion
            }}
          </p>
          <p>
            {{ $t('hat.managementModal.setting.hint.debug.category') }}：{{
              deviceInfo.type === 'BASE'
                ? $t('hat.managementModal.setting.hint.debug.basic')
                : $t('hat.managementModal.setting.hint.notice.plus')
            }}<a
              :href="deviceInfo.detailUrl"
              target="_blank"
              v-if="deviceInfo.detailUrl"
              ><x-icon type="tc-icon-export" style="margin: 4px 0 0 2px"
            /></a>
          </p>
          <p>
            ICCID：{{ deviceInfo.iotIccId
            }}<a-icon
              :type="
                deviceInfo.iccId === deviceInfo.iotIccId
                  ? 'check-circle'
                  : 'question-circle'
              "
              style="color: var(--primary); margin-left: 4px"
              v-if="deviceInfo.iccId"
            />
          </p>
          <p>
            {{ $t('hat.managementModal.setting.hint.debug.production') }}：{{
              $moment(deviceInfo.factoryTime).format('YYYY-MM-DD')
            }}
          </p>
        </a-col>
      </a-row>
    </a-form-model>
    <a-spin :spinning="loading" />
  </div>
</template>

<script>
import { Component, Vue, Inject, InjectReactive } from 'vue-property-decorator';
import { getFenceListById } from '@/services/smart-hat/geofence';
import { putOtherSetting } from '@/services/smart-hat/device-management';
import {
  getRuleList,
  getGroupList,
} from '@/services/smart-hat/device-management';
import SettingForm from './setting-form.vue';
import { replaceContent } from '@/views/hat/utils.js';

@Component({
  components: {
    SettingForm,
  },
})
export default class OtherSetting extends Vue {
  @InjectReactive('deviceDetail') deviceDetail;
  @InjectReactive('deviceDetail') detail;
  @Inject('UPDATE_DEVICE_DETAIL') updateDeviceDetail;

  form = {
    enable: true,
    type: 'SOS',
    defaultStartTime: null,
    defaultEndTime: null,
    noticeMemberType: 'SYSTEM_MEMBER',
    noticeMember: undefined,
    noticeMethodList: [],
    defaultLocation: undefined,
    content: '',
    num: 1,
  };
  isEdit = false;
  mounted() {
    this.getFenceList();
    this.getGroupList();
    this.getDeviceInfo();
    this.getRules();
  }
  loading = false;
  originalData = {};
  async getRules() {
    this.loading = true;
    try {
      const data = await getRuleList(this.deviceDetail.deviceId);
      this.loading = false;
      data.forEach(item => {
        if (item.type === 'SOS') {
          this.form.noticeMemberType = item.noticeMemberType || 'SYSTEM_MEMBER';
          this.form.noticeMethodList = item.noticeMethodList;
          this.originalData = item;
          this.form.content =
            this.replaceContent(item.content) ||
            '{成员姓名}在{发送时间}触发了SOS警报，详情见{位置链接}';
          if (item.noticeMemberType === 'SYSTEM_MEMBER') {
            this.members = JSON.parse(item.noticeMember).map(e => ({
              text: e.userName,
              value: e.userId,
              avatar: e.avatar,
            }));
          } else {
            this.form.noticeMember = item.noticeMember;
          }
        }
      });
    } catch {
      this.loading = false;
    }
  }
  handleChange(v) {
    const {
      noticeMemberType,
      noticeMethodList,
      noticeMember,
    } = this.originalData;
    if (v.target.value !== noticeMemberType) {
      this.form.noticeMethodList = [];
      this.form.noticeMember = undefined;
    } else {
      this.form.noticeMethodList = noticeMethodList;
      this.form.noticeMember = noticeMember;
    }
  }
  onBlur(e) {
    const { value } = e.target;
    this.form.content = replaceContent(value);
  }
  async handleSave() {
    if (this.members.length > 20) {
      this.$message.error(
        this.$t('hat.managementModal.setting.hint.other.verifyMsg'),
      );
      return;
    }
    const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    if (
      !phoneReg.test(this.form.noticeMember) &&
      this.form.noticeMemberType === 'PHONE_NUMBER'
    ) {
      this.$message.error(this.$t('common.MobileError'));
      return;
    }
    const data = {
      broadcastPlayFrequency: this.form.num,
      deviceId: this.deviceDetail.deviceId,
      geofenceId: this.form.defaultLocation,
      sos: {
        content: this.form.content
          .replace(/{成员姓名}/g, 'USER_NAME')
          .replace(/{发送时间}/g, 'SEND_TIME')
          .replace(/{位置链接}/g, 'LOCATION_URL')
          .replace(/{位置名称}/g, 'LOCATION_NAME'),
        noticeMember: this.form.noticeMember,
        noticeMemberType: this.form.noticeMemberType,
        noticeMethodList: this.form.noticeMethodList,
      },
    };
    if (this.form.noticeMethodList.includes('VOICE')) {
      data.sos.content = data.sos.content.replace(/LOCATION_URL/g, '');
    }
    if (this.form.noticeMemberType === 'SYSTEM_MEMBER') {
      data.sos.noticeMember = JSON.stringify(
        this.members.map(item => item.value),
      );
    }
    await putOtherSetting(data);
    await this.$message.success({
      content: this.$t('common.SuccessfullySaved'),
      duration: 0.5,
    });
    this.isEdit = false;
    await this.updateDeviceDetail();
    await this.getRules();
    await this.getDeviceInfo();
  }
  deviceInfo = {};
  async getDeviceInfo() {
    this.deviceInfo = this.detail;
    this.form.defaultLocation = this.deviceInfo.geofenceId;
    this.form.num = this.deviceInfo.broadcastPlayFrequency;
  }
  // 围栏列表
  fenceList = [];
  async getFenceList() {
    const data = {
      deviceGroupIds: this.deviceDetail.deviceId,
    };
    const records = await getFenceListById(data);
    this.fenceList = records.map(item => ({
      value: item.geofenceId,
      label: item.geofenceName,
    }));
  }
  // 编组列表
  groupList = [];
  async getGroupList() {
    const data = {
      current: 1,
      size: 200,
    };
    const { records } = await getGroupList(data);
    this.groupList = records;
  }
  // 选择成员
  members = [];
  range = [];
  view = false;
  updateRangeValue(data) {
    this.range = data[0];
  }
  // 向输入框光标位置插入文本
  async insertVariable(value) {
    const myField = document.querySelector('#input');
    if (myField.selectionStart || myField.selectionStart === 0) {
      let startPos = myField.selectionStart;
      let endPos = myField.selectionEnd;
      this.form.content =
        myField.value.substring(0, startPos) +
        value +
        myField.value.substring(endPos, myField.value.length);
      await this.$nextTick();
      myField.focus();
      myField.setSelectionRange(endPos + value.length, endPos + value.length);
    } else {
      this.form.content = value;
    }
    this.form.content = this.replaceContent(this.form.content);
  }
  replaceContent(content) {
    return content
      .replace(/USER_NAME/g, '{成员姓名}')
      .replace(/SEND_TIME/g, '{发送时间}')
      .replace(/LOCATION_URL/g, '{位置链接}')
      .replace(/LOCATION_NAME/g, '{位置名称}');
  }
}
</script>

<style lang="less" module>
.notify {
  margin-top: 10px;
  position: relative;
  .buttonGroups {
    position: absolute;
    z-index: 9999;
    right: 10px;
    top: 6px;
    .buttonItem {
      min-width: 50px;
      height: 30px;
      margin-left: 10px;
    }
  }

  .check {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .tags {
    span {
      cursor: pointer;
      background-color: var(--tag);
      padding: 3px 6px;
      border-radius: 6px;
      margin-right: 10px;
    }
  }
  .formLabel {
    color: var(--font);
    margin-right: 10px;
    width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  .marginLeft10 {
    margin-left: 10px;
  }
  .desc {
    color: var(--font-info);
  }
  .deviceInfo {
    & > p {
      margin-bottom: 10px;
    }
  }
}
</style>
